<template>
  <VAuthContainer class="reset-password-component">
    <div class="reset-password-component__header">
      <div class="reset-password-component__title">
        {{ $t('Forgot password?') }}
      </div>

      <IconUnlock />
    </div>

    <div class="reset-password-component__text">
      {{ $t('Enter your email for reset your password.') }}
    </div>

    <ResetPasswordForm />
  </VAuthContainer>
</template>

<script>
import ResetPasswordForm from '@/components/auth/resetPassword/ResetPasswordForm';
import VAuthContainer from '@/components/ui/Auth/VAuthContainer';
import IconUnlock from '@/assets/icons/unlock.svg';

export default {
  name: 'ResetPasswordComponent',
  components: {
    ResetPasswordForm,
    VAuthContainer,
    IconUnlock,
  },

  metaInfo() {
    return {
      title: this.$t('Reset password'),
    }
  },
  i18n: {
    messages: {
      'en': {
        'Forgot password?': 'Forgot password?',
        'Enter your email for reset your password.': 'Enter your email address and we\'ll send you a link to reset your password.',
        'Reset password': 'Reset password',
      },
      'ru': {
        'Forgot password?': 'Забыли пароль?',
        'Enter your email for reset your password.': 'Введите свой адрес электронной почты и мы отправим Вам письмо с ссылкой для сброса пароля.',
        'Reset password': 'Сброс пароля',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.reset-password-component {
  display: grid;
  grid-row-gap: 20px;
  padding: 26px;
  height: max-content;
  color: #4D505E;
}

.reset-password-component__header {
  display: flex;
  justify-content: space-between;
}

.reset-password-component__title {
  font-size: 18px;
  font-weight: 600;
  font-variation-settings: 'wght' 600;
  line-height: 25px;
}

.reset-password-component__text {
  font-size: 12px;
  font-weight: 400;
  font-variation-settings: 'wght' 400;
  line-height: 160%;
}
</style>
