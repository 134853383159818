<template>
  <form
    novalidate
    class="reset-password-form"
    @submit.prevent="sendResetLink"
  >
    <VInput
      name="email"
      type="email"
      :placeholder="$t('Email')"
      :value="form.email"
      :generalError="rulesForGeneralError"
      :disabled="inputDisabled"
      required
      class="reset-password-form__input"
      @change-input-value="changeForm($event, 'email')"
      @input-errors="onInputErrors"
      @show-error="onBlurInputWithError"
      @hide-error="onFocusInputWithError"
    >
      <IconEmail />
    </VInput>

    <ErrorsLog
      :errorsShowed="errorsShowed"
      :errors="errors"
      :generalError="generalError"
      :booleanRulesForGeneralError="booleanRulesForGeneralError"
      @error-enter="erorrEnter"
      @error-after-leave="errorAfterLeave"
      @general-error-enter="generalErrorEnter"
      @general-error-after-leave="generalErrorAfterLeave"
    />

    <VButton :isLoading="isLoading">
      {{ $t('Send reset link') }}
    </VButton>
  </form>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import { validate } from '@/utils/validate';
import { errorsLog } from '@/mixins/errorsLog';
import { authForms } from '@/mixins/authForms';
import ErrorsLog from '@/components/auth/ErrorsLog';
import VInput from '@/components/ui/Auth/VInput';
import VButton from '@/components/ui/Auth/VButton';
import IconEmail from '@/assets/icons/contact-us.svg';

export default {
  name: 'ResetPasswordForm',
  components: {
    ErrorsLog,
    VInput,
    VButton,
    IconEmail,
  },
  mixins: [
    errorsLog,
    authForms,
  ],

  data: () => ({
    form: {
      email: null,
    },
  }),
  computed: {
    ...mapState('general', [
      'language',
    ]),

    statusAllFields() {
      return this.form.email
        && this.generalError.text === validate.generalErrors.allHighlightedFieldsMustBeFilled;
    },
    formIsValid() {
      return this.form.email
        && !this.errors.length;
    },
  },

  methods: {
    ...mapMutations('auth', [
      'SET_EMAIL',
      'SET_EMAIL_LINK',
      'SET_EMAIL_SEND_FLAG',
    ]),
    ...mapActions('auth', [
      'resetToken',
    ]),

    async sendResetLink() {
      if (!this.formIsValid) {
        return this.setGeneralError(true, validate.generalErrors.allHighlightedFieldsMustBeFilled);
      }

      this.setFormLoadingState(true);

      try {
        const response = await this.resetToken({
          ...this.form,
          lang: this.language,
        })

        if (!response.data.success) {
          throw new Error('Nonexistent email address');
        }

        this.SET_EMAIL(this.form.email);
        this.SET_EMAIL_LINK(this.form.email.split('@')[1]);
        this.SET_EMAIL_SEND_FLAG(true);
      } catch(e) {
        this.setFormLoadingState(false);

        const error = e.response
          ? e.response.data.message
          : e.message;

        if (error === 'Network Error') {
          return this.setGeneralError(true, validate.generalErrors.connectionFalse);
        }

        switch(error) {
          case 'Network Error':
            this.setGeneralError(true, validate.generalErrors.connectionFalse);
            break;
          case 'Nonexistent email address':
            this.setGeneralError(true, validate.generalErrors.nonexistentEmail);
            break;
          default: this.setGeneralError(true, validate.generalErrors.tryAgain);
        }
      }
    },
  },

  i18n: {
    messages: {
      'en': {
        'Email': 'Email',
        'Send reset link': 'Send reset link',
      },
      'ru': {
        'Email': 'Электронная почта',
        'Send reset link': 'Отправить ссылку',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.reset-password-form {
  display: grid;
}

.reset-password-form__input {
  margin-bottom: 20px;
}
</style>
